body {
  color: #444;
  font-family: Open Sans, sans-serif;
}

a {
  color: #cd5c5c;
  text-decoration: none;
}

a:hover {
  color: #ffc85a;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Raleway, sans-serif;
}

.back-to-top {
  visibility: hidden;
  opacity: 0;
  z-index: 1996;
  width: 40px;
  height: 40px;
  background: #cd5c5c;
  border-radius: 4px;
  transition: all .4s;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.back-to-top i {
  color: #fff;
  font-size: 28px;
  line-height: 0;
}

.back-to-top:hover {
  color: #fff;
  background: #ffc550;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

#header {
  z-index: 1997;
  height: 80px;
  background: #f78989e3;
  transition: all .5s;
}

#header.header-transparent {
  background: none;
}

#header.header-scrolled {
  height: 60px;
  background: #f78989e3;
}

.navbar {
  padding: 0;
}

.navbar ul {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.navbar li {
  position: relative;
}

.navbar a, .navbar a:focus {
  color: #fff;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-weight: 600;
  transition: all .3s;
  display: flex;
}

.navbar a i, .navbar a:focus i {
  margin-left: 5px;
  font-size: 12px;
  line-height: 0;
}

.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #cd5c5c;
}

.navbar .dropdown ul {
  z-index: 199;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  border-radius: 4px;
  margin: 0;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  left: 14px;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  text-transform: none;
  color: #3b434a;
  letter-spacing: 1px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #cd5c5c;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.navbar .dropdown .dropdown ul {
  visibility: hidden;
  top: 0;
  left: calc(100% - 30px);
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 0;
  left: 100%;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.mobile-nav-toggle {
  color: #fff;
  cursor: pointer;
  font-size: 28px;
  line-height: 0;
  transition: all .5s;
  display: none;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  z-index: 1999;
  background: #24292ee6;
  transition: all .3s;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 55px 15px 15px;
  overflow-y: auto;
}

.navbar-mobile a, .navbar-mobile a:focus {
  color: #3b434a;
  padding: 10px 20px;
  font-size: 14px;
}

.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #cd5c5c;
}

.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  z-index: 199;
  opacity: 1;
  visibility: visible;
  background: #fff;
  margin: 10px 20px;
  padding: 10px 0;
  display: none;
  position: static;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #cd5c5c;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

#hero {
  width: 100%;
  height: 100vh;
  background: linear-gradient(45deg, rgba(205,92,92,1) 0%, rgba(255,130,130,1) 30%, rgba(255,219,219,1) 100%);
  position: relative;
}

#hero .hero-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  display: flex;
  position: absolute;
  inset: 0;
}

#hero h1 {
  color: #fff;
  margin: 0 0 10px;
  font-family: Satisfy, serif;
  font-size: 64px;
}

#hero h2 {
  color: #eee;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 50px;
  font-size: 13px;
  font-weight: 500;
}

#hero .btn-scroll {
  color: #fff9;
  transition: all .4s;
  animation: 1s ease-in-out infinite alternate-reverse both up-down;
}

#hero .btn-scroll i {
  font-size: 48px;
}

#hero .btn-scroll:hover {
  color: #cd5c5c;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 575px) {
  #hero h1 {
    font-size: 40px;
  }

  #hero h2 {
    text-align: center;
    margin-bottom: 30px;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}

section {
  padding: 60px 0;
}

.section-bg {
  background-color: #fff;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title h2 {
  text-transform: uppercase;
  color: #3b434a;
  z-index: 2;
  margin-bottom: 20px;
  padding-bottom: 0;
  font-size: 32px;
  font-weight: 700;
  position: relative;
}

.section-title span {
  color: #fad1d1;
  z-index: 1;
  text-transform: uppercase;
  font-size: 52px;
  font-weight: 700;
  line-height: 0;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.section-title p {
  z-index: 2;
  margin-bottom: 0;
  position: relative;
}

@media (max-width: 575px) {
  .section-title h2 {
    margin-bottom: 15px;
    font-size: 28px;
  }

  .section-title span {
    font-size: 38px;
  }
}

.breadcrumbs {
  min-height: 40px;
  background-color: #f4f5f6;
  margin-top: 78px;
  padding: 20px 0;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 60px;
  }
}

.breadcrumbs h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 300;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px;
  }
}

.breadcrumbs ol {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
  display: flex;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li:before {
  color: #6c757d;
  content: "/";
  padding-right: 10px;
  display: inline-block;
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

.about .content h3 {
  color: #3b434a;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 700;
}

.about .content ul {
  padding: 0;
  list-style: none;
}

.about .content ul li {
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.about .content ul strong {
  margin-right: 10px;
}

.about .content ul i {
  color: #cd5c5c;
  margin-right: 5px;
  font-size: 16px;
  line-height: 0;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .count-box {
  width: 100%;
}

.about .content .count-box i {
  color: #cd5c5c;
  float: left;
  font-size: 36px;
  line-height: 0;
  display: block;
}

.about .content .count-box span {
  color: #3b434a;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  display: block;
}

.about .content .count-box p {
  color: #5d6a75;
  padding: 15px 0 0;
  font-family: Raleway, sans-serif;
  font-size: 20px;
}

.about .content .count-box a {
  color: #5d6a75;
  margin-top: 20px;
  font-family: Satisfy, serif;
  font-size: 15px;
  font-weight: 600;
  transition: all .3s ease-in-out;
  display: block;
}

.about .content .count-box a:hover {
  color: #82909c;
}

.about img {
  max-height: 100%;
  max-width: 100%;
  min-height: 500px;
}

.services {
  background-color: #ffdbdb;
}

.services .icon-box {
  text-align: center;
  width: 100%;
  background: #fff;
  border: 1px solid #fff;
  padding: 30px;
  transition: all .3s ease-in-out;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 29px #4458901a;
}

.services .icon {
  text-align: center;
  width: 72px;
  height: 72px;
  background: #fff7f7;
  border-radius: 50%;
  margin: 0 auto 20px;
  padding-top: 17px;
  display: inline-block;
}

.services .icon i {
  color: #cd5c5c;
  font-size: 36px;
  line-height: 1;
}

.services .title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
}

.services .title a {
  color: #cd5c5c;
  transition: all .3s;
}

.services .description {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 28px;
}

.services .icon-box:hover {
  border-color: #cd5c5c;
}

.services .icon-box:hover .title a {
  color: #cd5c5c;
}

.contact .info-box {
  color: #444;
  text-align: center;
  padding: 20px 0 30px;
  box-shadow: 0 0 30px #d6d7d899;
}

.contact .info-box i.bx {
  color: #cd5c5c;
  background: #fff6e4;
  border-radius: 50%;
  padding: 15px;
  font-size: 24px;
}

.contact .info-box h3 {
  color: #777;
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
}

.contact .info-box p {
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

.contact .social-links {
  justify-content: center;
  margin-top: 15px;
  display: flex;
}

.contact .social-links a {
  color: #687683;
  border: 1px solid #dde1e4;
  border-radius: 50px;
  margin: 0 8px;
  padding: 14px;
  font-size: 18px;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

.contact .social-links a:hover {
  color: #fff;
  background: #cd5c5c;
  border-color: #cd5c5c;
}

.contact .php-email-form {
  padding: 27px;
  box-shadow: 0 0 30px #d6d7d899;
}

.contact .php-email-form .validate {
  color: red;
  margin: 0 0 15px;
  font-size: 13px;
  font-weight: 400;
  display: none;
}

.contact .php-email-form .error-message {
  color: #fff;
  text-align: left;
  background: #ed3c0d;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  color: #fff;
  text-align: center;
  background: #18d26e;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .loading {
  text-align: center;
  background: #fff;
  padding: 15px;
  display: none;
}

.contact .php-email-form .loading:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  border-radius: 50%;
  margin: 0 10px -6px 0;
  animation: 1s linear infinite animate-loading;
  display: inline-block;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  box-shadow: none;
  border-radius: 0;
  font-size: 14px;
}

.contact .php-email-form input::focus, .contact .php-email-form textarea::focus {
  background-color: #cd5c5c;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  color: #fff;
  background: #cd5c5c;
  border: 0;
  border-radius: 5px;
  padding: 10px 24px;
  transition: all .4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #ffc85a;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.praxis {
  background-color: #ffdbdb;
}

.praxis iframe {
  background-color: gray;
}

#footer {
  color: #fff;
  text-align: center;
  background: url("footer-bg.62f7b118.avif") top / cover no-repeat;
  padding: 80px 0;
  font-size: 14px;
  position: relative;
}

#footer:before {
  content: "";
  background: #0009;
  position: absolute;
  inset: 0;
}

#footer .container {
  position: relative;
}

#footer h3 {
  color: #fff;
  margin: 0 0 15px;
  padding: 0;
  font-family: Satisfy, serif;
  font-size: 36px;
  font-weight: 700;
  position: relative;
}

#footer p {
  margin: 0 0 40px;
  padding: 0;
  font-size: 15px;
  font-style: italic;
}

#footer .social-links {
  margin: 0 0 40px;
}

#footer .social-links a {
  color: #fff;
  text-align: center;
  width: 36px;
  height: 36px;
  background: #cd5c5c;
  border-radius: 50%;
  margin-right: 4px;
  padding: 8px 0;
  font-size: 18px;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

#footer .social-links a:hover {
  color: #fff;
  background: #f3a200;
  text-decoration: none;
}

#footer .copyright a {
  font-size: 18px;
}

#footer .copyright {
  margin: 0 0 5px;
}

#footer .credits {
  font-size: 13px;
}

/*# sourceMappingURL=index.e124f66c.css.map */
